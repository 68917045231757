@use "../abstract/variable" as *;
@use "../abstract/mixin" as *;
@use "../abstract/keyframe" as *;

.dashboard {
  margin: 12rem 0;
  @include responsive(56.3em) {
    margin: 8rem 0;
  }

  .shift {
    margin: -25rem 0 0;
    @include responsive(59.4em) {
      margin: unset;
    }
  }
}

.odds {
  background: $whiteSec;
  padding: 3rem 5rem;
  position: relative;
  transform: translateY(-35rem);
  border-radius: 1rem;
  @include responsive(59.4em) {
    transform: unset;
    margin: 0 0 5rem;
    padding: 3rem 0 0;
  }

  .tip {
    margin-top: 4rem;

    &--free {
      margin: 5rem 0;

      &:last-child {
        margin-bottom: unset;
      }
    }
  }

  table {
    background: #fff;
    margin-bottom: 4rem;
  }

  &__actions {
    text-align: center;
    margin: 5rem 0;
    @include responsive(59.4em) {
      margin: 3.5rem 0;
    }
  }

  &__header {
    background: #4a5e9e;
    padding: 2rem;
    text-align: center;
    text-transform: uppercase;
    color: #fff;
    width: 65%;
    margin: 0 auto;
    border-radius: 1rem;
    position: absolute;
    top: -3rem;
    left: 50%;
    transform: translateX(-50%);
    @include responsive(59.4em) {
      padding: 1.5rem 0;
    }
    @include responsive(48.13em) {
      width: 90%;
      font-size: 1.8rem;
    }

    h3 {
      @include responsive(48.13em) {
        font-size: 1.5rem;
      }
    }
  }

  &__info {
    margin: 2rem 0 5rem;

    h3 {
      margin: 2rem 0;
    }

    ul {
      padding-left: 3rem;
    }
  }
}
