@use "../abstract/variable" as *;
@use "../abstract/mixin" as *;
@use "../abstract/keyframe" as *;

.navigation {
  background: #1a1a1a;
  padding: 2rem 0;
  color: $white;

  &__wrap {
    display: flex;
    align-items: center;

    > div {
      display: flex;
      gap: 3rem;
      align-items: center;

      &:first-child {
        margin-right: auto;
      }
    }
  }

  &__logo {
    height: 3rem;
  }

  &__language {
    @include responsive(41.9em) {
      display: none;
    }
  }
}

.nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  list-style: none;
  gap: 1.5rem;
  @include responsive(74.9em) {
    position: fixed;
    right: -100%;
    top: 7rem;
    display: block;
    background: #fff;
    color: $black;
    width: 40%;
    z-index: 2;
    padding: 3rem 3rem;
    box-shadow: 0 10px 27px #0000001a;
    transition: all 0.3s;
    height: calc(100vh - 7rem);
  }
  @include responsive(50em) {
    width: 50%;
  }
  @include responsive(40.63em) {
    width: 60%;
  }
  @include responsive(29.4em) {
    width: 100%;
  }

  &.active {
    right: 0;
  }

  &__item:last-child {
    .btn {
      @include responsive(74.9em) {
        display: block;
        width: 100%;
        margin: 1rem 0 1.5rem;
        text-align: center;
      }

      &-outline-light {
        @include responsive(74.9em) {
          border: 1.3px solid $black;
          color: $black;
          background: transparent;
          transition: all 0.4s;

          &:hover {
            background: $black;
            color: $white;
          }
        }
      }
    }
  }

  &__link {
    color: currentColor;
    text-decoration: none;
    cursor: pointer;
    display: inline-block;
    padding-bottom: 0.4rem;
    border-bottom: 0.25rem solid transparent;
    transition: all 0.4s;
    text-align: center;

    &.active,
    &:hover {
      border-bottom: 0.25rem solid $colPrimary;
    }

    @include responsive(74.9em) {
      display: inline-block;
      width: 100%;
      height: 100%;
      border-bottom: 0.2rem solid transparent;
      transition: all 0.4s;
      padding: 1rem 0;
    }

    &:hover {
      @include responsive(74.9em) {
        border-bottom: 0.2rem solid $colPrimary;
        color: $colPrimary;
      }
    }
  }
}

.hamburger {
  display: none;
  cursor: pointer;
  @include responsive(74.9em) {
    display: inline-block;
  }

  .bar {
    display: block;
    width: 2.5rem;
    height: 0.3rem;
    margin: 0.5rem auto;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    background-color: #fff;
  }

  &.active {
    .bar:nth-child(1) {
      transform: translateY(0.8rem) rotate(45deg);
    }
    .bar:nth-child(3) {
      transform: translateY(-0.8rem) rotate(-45deg);
    }
    .bar:nth-child(2) {
      opacity: 0;
    }
  }
}

.disable-scroll,
body.disable-scroll {
  overflow: hidden;
}
