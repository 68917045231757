@use "../abstract/variable" as *;
@use "../abstract/mixin" as *;
@use "../abstract/keyframe" as *;

.container {
  max-width: 140rem;
  margin: auto;
  padding: 0 7rem;
  @include responsive(61.9em) {
    padding: 0 5rem;
  }
  @include responsive(46.9em) {
    padding: 0 3rem;
  }
  @include responsive(33.13em) {
    padding: 0 1.67rem;
  }
}

.txt-center {
  text-align: center !important;
}

// .flex {
//   display: flex;
//   flex-wrap: wrap;
//   gap: 1.5rem;
//   justify-content: flex-start;

//   > * {
//     &:first-child {
//       flex-grow: 2;
//     }
//   }
// }

.mr-1 {
  margin-right: 1rem;
}

.mr-2 {
  margin-right: 2rem;
}

.my-2 {
  margin: 2rem 0;
}

.my-3 {
  margin: 3rem 0;
}

.mb-3 {
  margin-bottom: 3rem;
}

.mb-2 {
  margin-bottom: 2rem;
}

.mb-1 {
  margin-bottom: 1rem;
}

.mt-5 {
  margin-top: 5rem;
}

.mt-3 {
  margin-top: 3rem;
}

.py-2 {
  padding: 2rem 0;
}

.text-pry {
  color: $colPrimary;
}

.text-sec {
  color: $prySec;
}

.text-dark {
  color: $black !important;
}
