@use "../abstract/variable" as *;
@use "../abstract/mixin" as *;

*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

// background: url(../../../public/assets/img/img-form.webp);
html {
  font-size: 62.5%;
  scroll-behavior: smooth;
  @include responsive(35.5em) {
    font-size: 57%;
  }
  @include responsive(18.8em) {
    font-size: 40%;
  }
}

body {
  width: 100vw;
  min-height: 100vh;
  background: #e6e6e6;
  overflow-x: hidden;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

main {
  min-height: 45vh;
}

a {
  color: #4d525b;
  text-decoration: none;
}

img {
  @include img-responsive;
}

.main-content {
  padding: 7rem 0;
  @include responsive(52.19rem) {
    padding: 4rem 0 7rem;
  }
}
