@use "../abstract/variable" as *;

@-webkit-keyframes loading-keys-app-loading {
  0%,
  80%,
  100% {
    opacity: 0.75;
    box-shadow: 0 0 $colPrimary;
    height: 32px;
  }

  40% {
    opacity: 1;
    box-shadow: 0 -8px $colPrimary;
    height: 40px;
  }
}

@keyframes loading-keys-app-loading {
  0%,
  80%,
  100% {
    opacity: 0.75;
    box-shadow: 0 0 $colPrimary;
    height: 32px;
  }

  40% {
    opacity: 1;
    box-shadow: 0 -8px $colPrimary;
    height: 40px;
  }
}

@keyframes spinner-fade9234 {
  0% {
    // background-color: #69717d;
    background-color: currentColor;
  }

  100% {
    background-color: transparent;
  }
}
