@use "../abstract/variable" as *;
@use "../abstract/keyframe" as *;

@mixin img-responsive {
  width: 100%;
  height: 100% ;
  // height: 100% !important;
  object-fit: cover;
}

@mixin responsive($viewport) {
  @media (max-width: $viewport) {
    @content;
  }
}

@mixin btn-outline($borderCol, $hovCol: $white, $color: $borderCol) {
  border: 1.3px solid $borderCol;
  color: $color;
  transition: all 0.4s;
  background: transparent;

  &:hover {
    background: $borderCol;
    color: $hovCol;
  }
}

@mixin btn($bg, $color: $white) {
  background: $bg;
  color: $color;
}
