@use "../abstract/variable" as *;
@use "../abstract/mixin" as *;
@use "../abstract/keyframe" as *;

.footer {
  background: #000;
  display: flex;
  color: $white;
  min-height: 50vh;
  overflow: hidden;

  .text-sec {
    margin: 1.2rem 0;
  }

  &__container {
    padding: 0 7rem;
    margin: auto;
    flex: 0 0 80%;
    @include responsive(74.13em) {
      padding: 0 4rem;
    }
    @include responsive(62.5em) {
      flex: 1;
      padding: 0 7rem;
      margin: 5rem auto;
    }
    @include responsive(61.9em) {
      padding: 0 5rem;
    }
    @include responsive(46.9em) {
      padding: 0 3rem;
    }
    @include responsive(33.13em) {
      padding: 0 1.67rem;
    }
  }

  &__wrap {
    display: flex;
    justify-content: space-between;
    gap: 1.5rem;
    @include responsive(61.9em) {
      flex-wrap: wrap;
    }
  }

  &__brand {
    flex: 2;
    @include responsive(61.9em) {
      flex: 0 0 50%;
      justify-self: center;
    }
    @include responsive(53.13em) {
      flex: 0 0 100%;
      margin: 0 0 3rem;
    }

    .age {
      display: flex;
      align-items: center;
      gap: 1rem;

      img {
        width: 4rem;
      }

      span {
        display: inline-block;
      }
    }
  }

  &__contact,
  &__link {
    flex: 1;
    @include responsive(61.9em) {
      flex: 0 0 45%;
    }
    @include responsive(53.13em) {
      flex: 0 0 45%;
    }
  }

  &__contact {
    img {
      width: 3rem;
    }

    .whatsapp-block {
      p {
        display: flex;
        align-items: center;
        gap: 1rem;
      }
    }

    .number {
      display: inline-block;
    }

    .social-block {
      .icons {
        display: flex;
        align-items: center;
        gap: 1rem;
        // background: #fff;
      }

      img {
        width: 3rem;
      }
    }
  }

  &__img {
    flex: 1;
    @include responsive(62.5em) {
      display: none;
    }

    img {
      @include img-responsive;
    }
  }

  &__nav {
    list-style: none;

    .nav__item {
      padding: 0.6rem 0.3rem;
      width: max-content;
    }

    .navLink {
      text-decoration: none;
      color: inherit;
    }
  }
}
