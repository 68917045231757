@use "../abstract/variable" as *;
@use "../abstract/mixin" as *;
@use "../abstract/keyframe" as *;

.btn {
  padding: 0.65rem 1.7rem;
  display: inline-block;
  text-decoration: none;
  color: currentColor;
  outline: none;
  border: none;
  cursor: pointer;

  &-flex {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;

    img {
      height: 1.7rem;
      flex: 0 0 15%;
    }
  }

  &-disabled {
    cursor: not-allowed;
  }

  &-danger {
    @include btn(#ca2929);
  }

  &-blue {
    @include btn(#1a212c);
  }

  &-dark {
    @include btn($black);
  }

  &-pry {
    @include btn($colPrimary);
  }

  &-outline {
    &-pry {
      @include btn-outline($colPrimary);
    }

    &-light {
      @include btn-outline($white, $black);
    }

    &-blue {
      @include btn-outline(#1a212c, $white, $black);
    }

    &-dark {
      @include btn-outline($black);
    }
  }

  &-block {
    display: block;
    width: 100%;
  }

  &-lrg {
    padding: 1rem 1.7rem;
  }

  &-round {
    border-radius: 2rem;
  }

  &-img {
    height: 1.7rem;
    flex: 0 0 10%;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      height: 100%;
      width: unset;
      object-fit: unset;
    }
  }
}
