@use "../abstract/variable" as *;
@use "../abstract/mixin" as *;
@use "../abstract/keyframe" as *;

.about {
  padding: 10rem 0 25rem;
  @include responsive(65.63em) {
    padding: 10rem 0;
  }
  @include responsive(37.2em) {
    padding: 4.7rem 0;
  }

  &__wrap {
    display: flex;
    align-items: center;
    overflow: hidden;
    @include responsive(67.5em) {
      margin: 0 0 5rem;
    }
    @include responsive(62.5em) {
      margin: 0 0 8rem;
    }
  }

  &__content {
    flex: 0 0 60%;
    @include responsive(49em) {
      flex: 0 0 100%;
    }

    .heading {
      font-size: 4rem;
      width: 70%;
      color: #000814;
      font-weight: 700;
      @include responsive(75em) {
        font-size: 3rem;
      }
      @include responsive(60.7em) {
        width: 85%;
      }
      @include responsive(50em) {
        font-size: 2.4rem;
      }
    }
  }

  .about__img {
    flex: 1;
    @include responsive(49em) {
      display: none;
    }

    img {
      width: 100%;
    }
  }
}

.newsletter {
  padding: 2rem;
  background: #fff;
  text-align: center;
  color: $black;
  border-radius: 1.4rem;
  display: flex;
  align-items: center;
  min-height: 35vh;
  width: 60%;
  position: absolute;
  top: -15rem;
  left: 20%;
  box-shadow: 0 0 3rem #00000026;
  margin: 0 0 3rem;
  @include responsive(65.63em) {
    position: unset;
    width: 100%;
    margin: 0 0 10rem;
  }
  @include responsive(50em) {
    display: none;
  }

  &__content {
    flex: 0 0 70%;

    h3 {
      font-size: 3rem;
    }

    p {
      width: 70%;
      margin: 2rem auto;
      font-weight: 600;
    }

    input {
      font-family: inherit;
      font-size: inherit;
    }

    .form {
      display: flex;
      gap: 1rem;
      align-items: stretch;
    }
  }

  &__img {
    flex: 1;

    img {
      @include img-responsive;
    }
  }

  .subscription {
    &__input {
      padding: 1rem 1.5rem;
      border: 1px solid $black;
      outline: none;
      border-radius: 1rem;

      &::placeholder {
        font-family: inherit;
        color: inherit;
      }
    }
  }
}

.winner {
  color: $white;
  background: $blueSec;
  min-height: 30vh;
  padding: 20rem 0 10rem;
  position: relative;
  @include responsive(65.63em) {
    padding: 10rem 0;
  }

  &__wrap {
    display: flex;
    margin: 7rem 0 3rem;
    gap: 2rem;
    @include responsive(65.63em) {
      margin: unset;
    }

    h3 {
      margin: 1rem 0;
      text-transform: uppercase;
    }
  }

  &__caption {
    flex: 2 0;
    text-align: center;
  }

  &__img {
    flex: 1 0;
    &:first-child {
      @include responsive(50em) {
        display: none;
      }
    }
    @include responsive(43.13em) {
      display: none;
    }

    img {
      @include img-responsive;
    }
  }
}

.feature {
  padding: 10rem 0;
  @include responsive(57.63em) {
    padding: 6rem 0;
  }
  @include responsive(28.63em) {
    padding: 3rem 0;
  }

  .recent-win {
    display: flex;
    justify-content: space-between;
    gap: 1.5rem;
    @include responsive(75em) {
      flex-direction: column;
    }

    &__blog {
      flex: 0 0 40%;
    }

    &__predictions {
      flex: 1 0;
      background: url(../../../public/assets/img/football.svg) no-repeat;
      background-size: cover;
    }

    .blog {
      @include responsive(75em) {
        &__wrap {
          grid-template-columns: repeat(4, [col-start] 1fr [col-end]);
          grid-template-rows: unset;
        }
      }
      @include responsive(52em) {
        &__wrap {
          grid-template-columns: repeat(3, [col-start] 1fr [col-end]);
        }
      }
      @include responsive(40em) {
        &__wrap {
          grid-template-columns: repeat(2, [col-start] 1fr [col-end]);
        }
      }
    }
  }
}
