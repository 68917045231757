@use "../abstract/variable" as *;
@use "../abstract/mixin" as *;
@use "../abstract/keyframe" as *;

.subscription {
  padding-bottom: 3rem;

  &__wrap {
    text-align: center;
    width: 63%;
    margin: auto;
    @include responsive(57.5em) {
      width: 84%;
    }
    @include responsive(31.3em) {
      width: 100%;
    }

    h3 {
      font-size: 3rem;
      margin: 0 0 0.65rem;
    }
  }

  &__header {
    margin: 0 0 5.79rem;
    @include responsive(59.38em) {
      margin: 0 0 3rem;
    }
  }

  &__block {
    display: flex;
    justify-content: center;
    gap: 4rem;
    width: 89%;
    margin: auto;
    flex-wrap: wrap;
    @include responsive(75em) {
      width: auto;
    }
    @include responsive(51.3em) {
      flex-direction: column;
      width: 55%;
    }
    @include responsive(45.6em) {
      width: 65%;
    }
    @include responsive(37.5em) {
      width: 75%;
    }
    @include responsive(26.9em) {
      width: 90%;
    }
  }

  &__discount {
    font-size: 1.4rem;
    color: $white;
    text-decoration: line-through;
  }

  &__card {
    box-shadow: 0 0 1rem #00000028;
    padding: 3rem;
    text-align: center;
    border-radius: 1rem;
    flex: 0 0 35%;
    position: relative;
    overflow: hidden;
    @include responsive(71.68em) {
      flex: 0 0 calc((80% - 4rem) / 2);
    }
    @include responsive(65.31em) {
      flex: 0 0 calc((90% - 4rem) / 2);
    }
    @include responsive(56.3em) {
      flex: 0 0 calc((100% - 4rem) / 2);
    }
    @include responsive(51.3em) {
      flex: 1;
    }

    &-header {
      h3 {
        font-size: 3rem;
      }
    }

    &--basic {
      background: #e6e6e6af;
      border: 0.15rem solid #1a212c;

      &::before {
        font-weight: 700;
        content: "Free";
        position: absolute;
        background: #1a212cde;
        color: #e6e6e6;
        top: 0;
        left: 0;
        padding: 2rem 5rem 1.5rem;
        transform: rotate(-45deg) translate(-2.5rem, -3.5rem);
        font-size: 1.3rem;
      }

      .subscription__feature {
        &:not(:last-child) {
          border-bottom: 1.6px solid #1a212c;
        }
      }
    }

    &--gold {
      background: $black;
      color: $white;
      border: 0.15rem solid $colPrimary;

      &::before {
        font-weight: 700;
        content: "VIP";
        position: absolute;
        background: $colPrimary;
        top: 0;
        left: 0;
        padding: 2rem 5rem 1.5rem;
        transform: rotate(-45deg) translate(-2.5rem, -3.5rem);
        font-size: 1.3rem;
      }
    }

    &--platinum {
      background: $colPrimary;
      color: $white;
    }
  }

  &__footer {
    margin: 1.4rem 0 0;
  }

  &__features {
    margin: 1.4rem 0 0;
    list-style: none;
  }

  &__feature {
    font-size: 1.3rem;
    padding: 1rem 0.3rem;

    &:not(:last-child) {
      border-bottom: 1.6px solid $colPrimary;
    }
  }
}
