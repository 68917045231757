@use "../abstract/variable" as *;
@use "../abstract/mixin" as *;
@use "../abstract/keyframe" as *;

.prediction {
  border-collapse: collapse;
  font-size: 0.9em;
  font-family: sans-serif;
  min-width: 40rem;
  box-shadow: 0 0 2rem #00000026;
  width: 100%;
  text-align: center;
  min-width: 64rem;

  .justify-left {
    text-align: left;
  }

  &__header {
    background-color: $black;
    color: $colPrimary;
    text-align: center;
  }

  th,
  td {
    padding: 1.3rem;
  }

  td img {
    height: 4rem;
  }

  tr:nth-child(even) {
    background: #e6e6e6c5;
  }
}

.tip {
  &__header {
    h3 {
      font-size: 2.3rem;
      margin: 0 0 1.3rem;
      width: max-content;
      @include responsive(50em) {
        font-size: 2rem;
      }
    }
  }

  &__flex {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 5rem;
    @include responsive(62.5em) {
      flex-direction: column;
      gap: 1rem;
      align-items: unset;
      overflow-x: auto;
    }

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &__block {
    flex: 0 0 70%;
    @include responsive(62.5em) {
      flex: 1;
      overflow-x: auto;
    }
  }

  &__preview {
    flex: 1;
    text-align: center;

    img {
      @include img-responsive;
      margin-bottom: 1.5rem;
      @include responsive(62.5em) {
        display: none;
      }
    }
  }
}
