@use "../abstract/variable" as *;
@use "../abstract/mixin" as *;
@use "../abstract/keyframe" as *;

.header {
  height: 100vh;
  width: 100vw;
  color: $white;
  position: relative;
  @include responsive(54.25em) {
    height: 90vh;
  }
  @include responsive(37.2em) {
    height: 80vh;
  }
  @include responsive(26.3em) {
    height: 64vh;
  }

  &--home {
    clip-path: polygon(0 0, 100% 0, 100% 90%, 0 100%);
    background: linear-gradient(90deg, #202020d0, #202020d0),
      url(../../../public/assets/img/banner.jpeg) no-repeat;
    background-size: cover;
    @include responsive(54.25em) {
      clip-path: unset;
    }
  }

  &--dashboard {
    background: $blueSec;
    height: 70vh;
    position: relative;
    @include responsive(59.4em) {
      height: auto;
    }

    .header__caption {
      top: 30%;
      @include responsive(59.4em) {
        top: 50%;
        display: none;
      }
    }
  }

  &__caption {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 40%;
    text-align: center;
    @include responsive(71.6em) {
      width: 60%;
    }
    @include responsive(39.3em) {
      top: 50%;
      transform: translate(-50%, -50%);
    }
    @include responsive(31.3em) {
      width: 80%;
    }
  }

  .heading-pry {
    font-size: 3.5rem;
    text-transform: uppercase;
    font-weight: 600;
    padding: 3rem 0 1rem;
    @include responsive(62.2em) {
      font-size: 3rem;
    }
    @include responsive(39.3em) {
      font-size: 2.6rem;
    }
  }
}
