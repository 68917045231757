@use "../abstract/variable" as *;
@use "../abstract/mixin" as *;
@use "../abstract/keyframe" as *;

.blog {
  &__heading {
    margin: 0 0 2rem;
    font-size: 1.7rem;
    color: $colPrimary;
  }

  &__content {
    padding: 0 1.1rem;
    background: #fff;
  }

  &__title {
    font-size: 1.45rem;
    padding: 1rem 0;

    > a:hover {
      text-decoration: underline;
    }
  }

  &__discription {
    font-size: 1.35rem;
    margin: 0 0 2rem;
  }

  &__item {
    color: $black;
    border-radius: 0.57rem;
    overflow: hidden;
  }

  &__wrap {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    gap: 2.5rem;
    @include responsive(75.25rem) {
      flex-direction: column;
    }

    &-grid {
      display: grid;
      grid-template-columns: repeat(2, [col-start] 1fr [col-end]);
      grid-template-rows: repeat(2, [row-start] 1fr [row-end]);
      gap: 1.5rem;
    }

    &-flex {
      display: flex;
      column-gap: 2rem;
      row-gap: 2.8rem;
      flex-wrap: wrap;
      margin: auto;
      justify-content: center;
      @include responsive(86.8em) {
        width: 85%;
      }
      @include responsive(73.43em) {
        width: unset;
      }
      @include responsive(57.5em) {
        justify-content: center;
      }

      .blog__item {
        background: #fff;
        border-radius: 0.6rem;
        box-shadow: $box-shadow;
        align-self: flex-start;
        flex: 0 0 calc((100% - (2rem * 3)) / 4);
        @include responsive(86.8em) {
          flex: 0 0 calc((90% - (2rem * 2)) / 3);
        }
        @include responsive(81.25em) {
          flex: 0 0 calc((100% - (2rem * 2)) / 3);
        }
        @include responsive(57.5em) {
          flex: 0 0 calc((80% - 2rem) / 2);
        }
        @include responsive(52.81em) {
          flex: 0 0 calc((90% - 2rem) / 2);
        }
        @include responsive(45.63em) {
          flex: 0 0 calc((100% - 2rem) / 2);
        }
        @include responsive(31.3em) {
          flex: 0 0 60%;
        }
        @include responsive(28.13em) {
          flex: 0 0 73%;
        }
        @include responsive(21.56em) {
          flex: 0 0 83%;
        }
      }
    }
  }

  &__img {
    height: 15rem;

    &--lrg {
      height: 17rem;
    }

    img {
      @include img-responsive;
      filter: brightness(84%);
    }
  }
}

.blogPost {
  flex: 1;
  @include responsive(71.87em) {
    margin-bottom: 3rem;
  }

  &__imgCaption {
    width: 100%;
    height: 40rem;
    margin-bottom: 3rem;
    @include responsive(75.25rem) {
      width: 65rem;
    }
    @include responsive(49.38rem) {
      width: auto;
      height: 30rem;
    }
    @include responsive(28.56rem) {
      height: 26rem;
    }

    img {
      filter: brightness(75%);
    }
  }

  &__title {
    font-size: 2.6rem;
    margin: 0 0 2rem;
    @include responsive(40em) {
      font-size: 2.2rem;
    }
  }

  .icons {
    display: flex;
    gap: 1rem;
    margin-bottom: 1.5rem;
  }
}

.relatedPost {
  flex: 0 0 36%;
  @include responsive(71.87em) {
    flex: 0 0 30%;
  }

  &__listing {
    @include responsive(75.25rem) {
      display: flex;
      gap: 1.3rem;
      flex-wrap: wrap;
      overflow: hidden;
    }
    @include responsive(25rem) {
      flex-direction: column;
    }
  }

  &__header {
    margin: 0 0 1.6rem;
  }

  &__item {
    display: flex;
    margin-bottom: 1.3rem;
    gap: 1rem;
    font-weight: 500;
    cursor: pointer;
    @include responsive(75.25rem) {
      flex: 0 0 40rem;
    }
    @include responsive(58.13rem) {
      flex: 0 0 calc((100% - 1.3rem) / 2);
    }
    @include responsive(49.38rem) {
      flex: 0 0 40rem;
    }
    @include responsive(25rem) {
      flex: 1;
    }
  }

  &__imgWrap {
    flex: 1;
    height: 10rem;
  }

  &__title {
    flex: 0 0 58%;
    font-size: 1.4rem;

    > a:hover {
      text-decoration: underline;
    }
  }
}
