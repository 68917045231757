@use "../abstract/variable" as *;
@use "../abstract/mixin" as *;
@use "../abstract/keyframe" as *;
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;900&display=swap");

body {
  font-family: "Inter", sans-serif;
  font-size: 1.6rem;
  line-height: 1.5;
  color: $blueSec;
}
