@use "../abstract/variable" as *;
@use "../abstract/mixin" as *;
@use "../abstract/keyframe" as *;

.profile {
  width: 75%;
  margin: 0 auto;
  @include responsive(74.9em) {
    width: 80%;
    margin: auto;
  }
  @include responsive(46.88em) {
    width: auto;
  }

  &__wrap {
    display: flex;
    align-items: flex-start;
  }

  h4,
  h3 {
    margin: 0 0 1.5rem;
  }

  .card {
    padding: 2rem;
    border: 1.2px solid #00081450;
    border-radius: 0.6rem;
    margin-bottom: 3rem;
    overflow: hidden;

    &-shadow {
      box-shadow: $box-shadow;
    }

    label {
      color: $blueSec;
    }

    &__title {
      margin-bottom: 2rem;
    }

    h3 {
      color: currentColor;
    }

    .prediction {
      background: #ffffff75;
      min-width: 100%;
      max-width: 100%;
      box-shadow: unset;
    }

    &-danger {
      border: 1.2px solid #ca292996;
      color: #ca2929;
      background: #f5b7b757;

      p {
        margin: 0 0 2rem;
      }
    }

    &-secoundry {
      color: $colPrimary;
      background: #f9993347;
      border-radius: 0.8rem;
      backdrop-filter: blur(6px);
      -webkit-backdrop-filter: blur(6px);
      border: 1.2px solid #f999334a;
    }
  }

  &__nav {
    flex: 0 0 32%;
    @include responsive(56.25em) {
      display: none;
    }

    .navbar {
      h4 {
        color: $prySec;
      }

      &__section {
        margin-bottom: 3rem;
      }

      ul {
        width: 90%;
        margin-left: auto;
        list-style: none;
      }

      li {
        padding: 0.75rem 0 0.75rem 0.9rem;
        border-left: 0.29rem solid transparent;
        transition: 0.4s all ease-in-out;

        &.active,
        &:hover {
          border-left: 0.29rem solid $colPrimary;
          color: $colPrimary;
        }
      }

      a {
        text-decoration: none;
        color: currentColor;
      }
    }
  }

  &__content {
    flex: 1;
    overflow: hidden;
  }

  &__caption {
    text-align: center;
    p {
      font-size: 1.3rem;
    }
  }
}
