@use "../abstract/variable" as *;
@use "../abstract/mixin" as *;
@use "../abstract/keyframe" as *;

.tab {
  overflow: hidden;

  &__nav {
    display: flex;
    background: #424040;
    gap: 1rem;

    div {
      label {
        padding: 1.5rem;
        cursor: pointer;
        display: inline-block;
        border-top: 0.12rem solid transparent;
        border-left: 0.2rem solid transparent;
        color: #ccc;

        &:has(~ input[type="radio"]:checked) {
          background: #212020;
        }
      }

      input[type="radio"] {
        display: none;
      }
    }
  }

  &__content {
    // padding: 0 0 1.5rem;
    overflow-x: scroll;
    border-bottom: 0.2rem solid #2a2828;
    border-left: 0.2rem solid #2a2828;
    border-right: 0.2rem solid #2a2828;
    z-index: -1;
    display: none;
    &::-webkit-scrollbar {
      display: none;
    }
  }

  .active {
    display: block;
  }
}
