:root {
  --black: #1a1a1a;
  --white: #e6e6e6;
  --whiteSec: #f7f7f7;
  --primary: #f99933;
  --primary-sec: #c77a29;
  --gery: #808080;
  --blueSec: #000814;
}

$black: var(--black);
$white: var(--white);
$colPrimary: var(--primary);
$prySec: var(--primary-sec);
$grey: var(--grey);
$blueSec: var(--blueSec);
$whiteSec: var(--whiteSec);
$box-shadow: 0 0.4rem 3rem #0000001f;
