@use "../abstract/variable" as *;
@use "../abstract/mixin" as *;
@use "../abstract/keyframe" as *;

.form {
  &--or {
    margin: 4rem 0;
    .flex {
      gap: 1rem;
      align-items: center;
    }

    .line {
      width: 45%;
      height: 1px;
      border-bottom: 1px solid #0000009a;
    }

    .paragraph {
      flex: 1 0;
      text-align: center;
      text-transform: uppercase;
    }
  }

  &__group {
    display: flex;
    margin: 0 0 2rem;
    gap: 1.5rem;
    flex-wrap: wrap;

    .input__group {
      margin-bottom: 0;
      flex-basis: 20rem;
    }
  }

  &__socials {
    margin: 4rem 0 0;
    text-align: center;

    button {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 1rem;
      font-size: 1.65rem;
    }
  }
}

.input__group {
  flex: 1;
  margin: 0 0 2rem;

  input,
  label {
    display: block;
    width: 100%;
  }

  input:not([type="submit"]) {
    padding: 0.8rem 1rem;
    outline: none;
    border: 0.15rem solid #00000038;
    border-radius: 0.5rem;

    &:focus {
      background: #cccccc88;
    }
  }

  label {
    margin: 0 0 1rem;
    font-size: 1.3rem;
    font-weight: 600;
    color: #363131;
  }

  .verify {
    color: $black;
  }

  &.submit {
    margin: 3rem 0 1.5rem;
  }

  .text-right {
    font-size: 1.4rem;
    font-weight: 600;

    a {
      text-decoration: none;
      color: $black;
      cursor: pointer;
      display: inline-block;
      padding-bottom: 0.3rem;
      text-decoration: underline;
    }
  }
}

.register {
  display: flex;
  margin: 0 auto;
  height: 65rem;
  width: 80%;
  border-radius: 1rem;
  box-shadow: #110c2e26 0 0 10rem 0;
  overflow: hidden;
  background: #fff;
  @include responsive(62.5em) {
    width: 60%;
    height: auto;
  }
  @include responsive(54.4em) {
    width: 70%;
  }
  @include responsive(41.3em) {
    width: 80%;
  }
  @include responsive(31.3em) {
    width: 100%;
  }

  &-sm {
    height: 40rem;
    @include responsive(62.5em) {
      height: auto;
    }
  }

  &__img {
    flex: 0 0 45%;
    height: 100%;
    align-self: start;
    background: url(../../../public/assets/img/img-form.webp);
    background-size: cover;
    @include responsive(62.5em) {
      display: none;
    }

    img {
      width: 100%;
    }
  }

  &__form {
    flex: 1;
    padding: 3rem;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    @include responsive(62.5em) {
      justify-content: flex-start;
    }
    &::-webkit-scrollbar {
      display: none;
    }

    h3 {
      font-size: 2.4rem;
      margin: 0 0 3rem;
      color: $colPrimary;
      text-align: center;
      @include responsive(62.5em) {
        text-align: center;
      }
    }

    &.register-top {
      justify-content: flex-start;
    }
  }
}

.verify {
  width: 70%;
  margin: auto;
  text-align: center;
  min-height: 50vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  @include responsive(35em) {
    width: 85%;
  }
  @include responsive(28.13) {
    width: 100%;
  }

  .heading {
    margin: 0 0 5rem;

    h3 {
      font-size: 2.7rem;
      color: $colPrimary;
      @include responsive(28.13) {
        font-size: 1.8rem;
      }
    }
  }

  .input__group {
    text-align: left;
  }

  &-block {
    width: max-content;
    margin: 0 auto;
    label[for="otp-email"] {
      margin-bottom: 1.4rem;
    }

    input {
      width: 30rem;
      display: inline-block;
    }
  }

  .otp {
    &-flex {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 30rem;

      input {
        width: 5rem;
        height: 5rem;
        border: 0.2rem solid $colPrimary;
        outline: none;
        padding: 1rem;
        text-align: center;
        font-size: 1.8rem;
        font-weight: 600;

        &:not(:last-child) {
          border-right: 0.1rem;
        }
      }
    }
  }
}
