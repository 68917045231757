@use "../abstract/variable" as *;
@use "../abstract/mixin" as *;
@use "../abstract/keyframe" as *;

.blockquote {
  padding: 0 1.5rem;
  @include responsive(38.8em) {
    padding-left: 1rem;
  }

  &-pry {
    border-left: 0.2rem solid $colPrimary;
    @include responsive(38.8em) {
      border-left: 0.3rem solid $colPrimary;
    }
  }

  &__title {
    color: $prySec;
  }
}

.feature {
  &__service {
    margin: 7rem 0 0;
    @include responsive(50em) {
      margin: 5rem 0 0;
    }
  }
}
