@use "../abstract/variable" as *;
@use "../abstract/mixin" as *;
@use "../abstract/keyframe" as *;

.alert {
  padding: 1rem 1.5rem;
  display: flex;
  align-items: center;
  background: #cccccc;
  position: fixed;
  right: 2.5rem;
  top: 10rem;
  box-shadow: 0 0 1.4rem #7272725d;
  border-radius: 0.6rem;
  z-index: 20;
  font-size: 1.3rem;
  justify-content: space-between;
  gap: 1.5rem;
  min-width: 20rem;
  transform: translateX(70rem);
  color: #141414;

  &-success {
    background: #abf5ab;
    border-left: 3px solid #2ee72e;
  }

  &-danger {
    background: #f9c8c8;
    border-left: 3px solid #f07676;
  }

  .icon {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  &.show {
    transition: all 0.3s ease-out;
    transform: translateX(0);
    opacity: 1;
  }

  &.hide {
    transition: all 0.3s ease-out;
    transform: translateX(70rem);
    opacity: 0;
  }
}

.close {
  position: relative;
  display: inline-block;
  width: 1.3rem;
  height: 1.3rem;
  overflow: hidden;
  &:hover {
    &::before,
    &::after {
      background: $colPrimary;
    }
  }

  &::before,
  &::after {
    content: "";
    position: absolute;
    height: 2px;
    width: 100%;
    top: 50%;
    left: 0;
    margin-top: -1px;
    background: #000;
  }

  &::before {
    transform: rotate(45deg);
  }

  &::after {
    transform: rotate(-45deg);
  }

  &.big {
    transform: scale(3);
  }

  &.hairline {
    &::before,
    &::after {
      height: 1px;
    }
  }

  &.thick {
    &::before,
    &::after {
      height: 4px;
      margin-top: -2px;
    }
  }

  &.black {
    &::before,
    &::after {
      height: 8px;
      margin-top: -4px;
    }
  }

  &.heavy {
    &::before,
    &::after {
      height: 12px;
      margin-top: -6px;
    }
  }

  &.pointy {
    &::before,
    &::after {
      width: 200%;
      left: -50%;
    }
  }

  &.rounded {
    &::before,
    &::after {
      border-radius: 5px;
    }
  }

  &.blades {
    &::before,
    &::after {
      border-radius: 5px 0;
    }
  }

  &.warp {
    &::before,
    &::after {
      border-radius: 120% 0;
    }
  }

  &.fat {
    &::before,
    &::after {
      border-radius: 100%;
    }
  }
}

small {
  display: block;
  padding: 5rem;
  font: 0.8rem/1 sans-serif;
  color: #777;
  text-align: center;

  a {
    color: $black;
    text-decoration: none;
    border-bottom: 1px solid #ccc;

    &:hover {
      border-bottom-color: #222;
    }
  }
}
